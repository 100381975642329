<template>
  <router-view />
</template>

<style lang="scss">
body {
  margin: 0;
}

.col {
  width: 100%;
}

.c2m-app::v-deep {
  .p-sidebar {
    background-color: red;
    padding: 0;
  }
}

//.c2m-app ::deep(.p-sidebar) {
//  background-color: red;
//  padding: 0;
//}

//.c2m-app > .p-sidebar .p-sidebar-content {
//  padding: 0;
//}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

//.no-box-shadow {
//  box-shadow: none !important;
//}
</style>
