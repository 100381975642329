<template>
  <div class="grid grid-nogutter mt-5">
    <div class="col text-center mt-5">
      <img class="mt-5" alt="Capital2Market" src="../assets/c2m-logo.png" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  components: {},
  setup() {
    // const store = useStore();
  },
  data() {
    return {};
  },
});
</script>
