/*
{
  "address": "24010 PARTNERSHIP BOULEVARD",
  "change_date": "080315",
  "city": "RUTHER GLEN",
  "code": 200,
  "customer_name": "UNION BANK & TRUST",
  "data_view_code": "1",
  "institution_status_code": "1",
  "message": "OK",
  "new_routing_number": "000000000",
  "office_code": "O",
  "record_type_code": "1",
  "rn": "051403164",
  "routing_number": "051403164",
  "state": "VA",
  "telephone": "800-990-4828",
  "zip": "22546"
}
 */

import { Request } from "@/api/services/request";
import { AxiosResponse } from "axios";

export interface RoutingNumberResponse {
  code: number;
  rn: string;
  message: string;
  address?: string;
  change_date?: string;
  city?: string;
  customer_name?: string;
  data_view_code?: string;
  institution_status_code?: string;
  new_routing_number?: string;
  office_code?: string;
  record_type_code?: string;
  routing_number?: string;
  state?: string;
  telephone?: string;
  zip?: string;
}

export class RoutingNumbers extends Request {
  public lookup(rn: string): Promise<AxiosResponse> {
    return this.get(`/routing-number-info/lookup/${rn}`, null);
  }
}
