
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  components: {},
  setup() {
    // const store = useStore();
  },
  data() {
    return {};
  },
});
