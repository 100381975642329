import { User, Auth } from "@/interfaces";

export type State = {
  auth: Auth;
  user: User;
  v1SessionId: string; // v1
};

export const state: State = {
  auth: <Auth>{},
  user: <User>{},
  v1SessionId: "",
};
