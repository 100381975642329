import { RouteRecordRaw } from "vue-router";
import InvestNowProfile from "@/views/v1/InvestNowProfile.vue";

export const investNowProfileV1Routes: Array<RouteRecordRaw> = [
  {
    path: "invest-now",
    component: InvestNowProfile,
    meta: {},
    children: [
      {
        path: "",
        redirect: { name: "home" },
        meta: {},
      },
      {
        path: ":id",
        name: "invest-now-v1",
        component: () =>
          import(
            "@/components/v1/invest-now-profile-v1/InvestNowProfileForm.vue"
          ),
        meta: {},
      },
    ],
  },
];
