import axios, { AxiosResponse } from "axios";
// import { state } from "@/state";
import { store } from "@/store";

interface Headers {
  [key: string]: string;
}

export class v1Request {
  url: string = process.env.VUE_APP_API_V1;

  headers(): Headers {
    const h: Headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Cache-control": "no-cache",
      "Custom-Test-Header": "Testing",
    };

    // todo: fetch user's IP from remote service and provide to request
    h["Api-Request-User-IP"] = "1.2.3.4";

    if (store.getters.getV1SessionId !== "") {
      h["api-session-id"] = store.getters.getV1SessionId;
    }

    console.log(h);

    return h;
  }

  public get<T>(endpoint: string, params: T): Promise<AxiosResponse> {
    return axios({
      method: "GET",
      url: `${this.url}${endpoint}`,
      params: params,
      headers: this.headers(),
    });
  }

  public post<T, R>(url: string, data: T, params: R): Promise<AxiosResponse> {
    return axios({
      method: "POST",
      url: `${this.url}${url}`,
      params: params,
      data: JSON.stringify(data),
      headers: this.headers(),
    });
  }

  public postBlob<T, R>(
    url: string,
    data: T,
    params: R
  ): Promise<AxiosResponse> {
    return axios({
      method: "POST",
      url: `${this.url}${url}`,
      responseType: "blob",
      params: params,
      data: JSON.stringify(data),
      headers: this.headers(),
    });
  }

  public postForm<T, R>(
    url: string,
    data: T,
    params: R
  ): Promise<AxiosResponse> {
    return axios({
      method: "POST",
      url: `${this.url}${url}`,
      responseType: "blob",
      params: params,
      data: data,
      headers: this.headers(),
    });
  }
}
