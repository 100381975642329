import { GetterTree } from "vuex";
import { State } from "@/store/state";
import { Auth, User } from "@/interfaces";

export type Getters = {
  getAuth(state: State): Auth;
  getUser(state: State): User;
  getV1SessionId(state: State): string; // v1
};

export const getters: GetterTree<State, State> & Getters = {
  getAuth(state): Auth {
    return state.auth;
  },
  getUser(state: State): User {
    return state.user;
  },
  getV1SessionId(state: State): string {
    return state.v1SessionId;
  },
};
