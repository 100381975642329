import { RouteRecordRaw } from "vue-router";
import InvestNowMakeInvestment from "@/views/v1/InvestNowMakeInvestment.vue";

export const investNowMakeInvestmentV1Routes: Array<RouteRecordRaw> = [
  {
    path: "make-investment",
    component: InvestNowMakeInvestment,
    meta: {},
    children: [
      {
        path: "",
        redirect: { name: "home" },
        meta: {},
      },
      {
        path: "sign/:id/:sid",
        name: "invest-now-sign-v1",
        component: () =>
          import(
            "@/components/v1/invest-now-investment-v1/InvestNowSignature.vue"
          ),
        meta: {},
      },
      {
        path: "complete/:id",
        name: "invest-now-complete-v1",
        component: () =>
          import(
            "@/components/v1/invest-now-investment-v1/InvestNowInvestmentComplete.vue"
          ),
        meta: {},
      },
      {
        path: ":token",
        name: "invest-now-make-investment-v1",
        component: () =>
          import(
            "@/components/v1/invest-now-investment-v1/InvestNowInvestmentForm.vue"
          ),
        meta: {},
      },
    ],
  },
];
