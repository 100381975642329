import { v1, v1HelloSign, User, RoutingNumbers } from "./services";

export class Api {
  public v1: v1;
  public v1HelloSign;
  public user: User;
  public routingNumber: RoutingNumbers;

  constructor() {
    this.v1 = new v1();
    this.v1HelloSign = new v1HelloSign();

    this.user = new User();

    this.routingNumber = new RoutingNumbers();
  }
}
