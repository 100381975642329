import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
// import Sandbox from "@/views/sandbox/Sandbox.vue";

// v1 Routes
import { V1Routes } from "@/router/routes/v1";
// import { investNowProfileV1Routes } from "@/router/routes/invest-now-v1/invest-now-profile-v1";
// import { investNowMakeInvestmentV1Routes } from "@/router/routes/invest-now-v1/invest-now-make-investment-v1";

// v2 Routes
// import { adminRoutes } from "@/router/routes/admin";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  // {
  //   path: "/sandbox",
  //   name: "Sandbox",
  //   component: Sandbox,
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  // v1 Routes
  ...V1Routes,
  // ...investNowProfileV1Routes,
  // ...investNowMakeInvestmentV1Routes,
  // v2 Routes
  // ...adminRoutes, // administrative routes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
