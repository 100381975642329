import axios, { AxiosResponse } from "axios";
// import { state } from "@/state";
import { store } from "@/store";

interface Headers {
  [key: string]: string;
}

export class Request {
  url: string = process.env.VUE_APP_API;

  headers(): Headers {
    const h: Headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Cache-control": "no-cache",
      "Custom-Test-Header": "Testing",
    };

    // const auth = state.auth;
    // if (auth && auth.token) {
    //   h["Access-Token"] = auth.token;
    // }

    if (store.state.auth && store.state.auth.token) {
      h["Access-Token"] = store.state.auth.token;
    }

    console.log(h);

    return h;
  }

  public get<T>(endpoint: string, params: T): Promise<AxiosResponse> {
    return axios({
      method: "GET",
      url: `${this.url}${endpoint}`,
      params: params,
      headers: this.headers(),
    });
  }

  public post<T, R>(url: string, data: T, params: R): Promise<AxiosResponse> {
    return axios({
      method: "POST",
      url: `${this.url}${url}`,
      params: params,
      data: JSON.stringify(data),
      headers: this.headers(),
    });
  }
}
