import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationType } from "@/store/mutations";
import { State } from "@/store/state";
import { Auth, User } from "@/interfaces";

export enum ActionTypes {
  SetAuth = "SET_AUTH",
  ClearAuth = "CLEAR_AUTH",
  SetUser = "SET_USER",
  ClearUser = "CLEAR_USER",
  SetV1SessionId = "SET_V1_SESSION_ID",
  ClearV1SessionId = "CLEAR_V1_SESSION_ID",
}

type ActionAugments = Omit<ActionContext<State, State>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
};
export type Actions = {
  [ActionTypes.SetAuth](context: ActionAugments, auth: Auth): void;
  [ActionTypes.ClearAuth](context: ActionAugments): void;
  [ActionTypes.SetUser](context: ActionAugments, user: User): void;
  [ActionTypes.ClearUser](context: ActionAugments): void;
  [ActionTypes.SetV1SessionId](context: ActionAugments, id: string): void;
  [ActionTypes.ClearV1SessionId](context: ActionAugments): void;
};

export const actions: ActionTree<State, State> & Actions = {
  [ActionTypes.SetAuth]({ commit }, auth: Auth): void {
    commit(MutationType.SetAuth, auth);
  },
  [ActionTypes.ClearAuth]({ commit }): void {
    commit(MutationType.SetAuth, <Auth>{});
  },
  [ActionTypes.SetUser]({ commit }, user: User): void {
    commit(MutationType.SetUser, user);
  },
  [ActionTypes.ClearUser]({ commit }): void {
    commit(MutationType.SetUser, <User>{});
  },
  [ActionTypes.SetV1SessionId]({ commit }, id: string): void {
    commit(MutationType.SetV1SessionId, id);
  },
  [ActionTypes.ClearV1SessionId]({ commit }): void {
    commit(MutationType.SetV1SessionId, "");
  },
};
