import {
  createStore,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions,
  createLogger,
} from "vuex";
import VuexPersistence from "vuex-persist";
import { State, state } from "@/store/state";
import { Mutations, mutations } from "@/store/mutations";
import { Actions, actions } from "@/store/actions";
import { Getters, getters } from "@/store/getters";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: process.env.VUE_APP_STORAGE,
});

export const store = createStore<State>({
  plugins:
    process.env.NODE_ENV === "development"
      ? [createLogger(), vuexLocal.plugin]
      : [vuexLocal.plugin],
  state,
  mutations,
  actions,
  getters,
});

export function useStore(): Store {
  return store as Store;
}

export type Store = Omit<
  VuexStore<State>,
  "getters" | "commit" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
};
