import { AxiosResponse } from "axios";
import { v1Request } from "./v1Request";
import * as Payloads from "./v1Payloads";

export class v1 extends v1Request {
  constructor() {
    super();
  }

  public getOfferingCompany(
    d: Payloads.GetOfferingCompany
  ): Promise<AxiosResponse> {
    return this.post("/offering/company/get", d, null);
  }

  public getOfferingHeader(d: Payloads.PostIcon): Promise<AxiosResponse> {
    return this.postBlob("/document/download", d, null);
  }

  public login(d: Payloads.PostLogin): Promise<AxiosResponse> {
    return this.post("/user/login", d, null);
  }

  public tempLogin(d: Payloads.PostTempLogin): Promise<AxiosResponse> {
    return this.post("/user/temp-login", d, null);
  }

  public forgotPassword(
    d: Payloads.PostForgotPassword
  ): Promise<AxiosResponse> {
    return this.post("/user/request-password-reset-token", d, null);
  }

  public validatePasswordResetToken(
    d: Payloads.PostValidatePasswordToken
  ): Promise<AxiosResponse> {
    return this.post("/user/validate-reset-password", d, null);
  }

  public resetPassword(d: Payloads.PostResetPassword): Promise<AxiosResponse> {
    return this.post("/user/secure-update-password", d, null);
  }

  public investorCreate(
    d: Payloads.PostInvestorCreate
  ): Promise<AxiosResponse> {
    return this.post("/investor/create", d, null);
  }

  public individualDocumentTypes(
    d: Payloads.PostDocumentTypes
  ): Promise<AxiosResponse> {
    return this.post("/document/list-individual-document-types", d, null);
  }

  public getInvestment(
    d: Payloads.PostInvestmentToken
  ): Promise<AxiosResponse> {
    return this.post("/investment/validate-token", d, null);
  }

  public postMakeInvestmentData(
    d: Payloads.GetMakeInvestmentData
  ): Promise<AxiosResponse> {
    return this.post("/investment/get-make-data", d, null);
  }

  public saveInvestment(
    d: Payloads.PostSaveInvestment
  ): Promise<AxiosResponse> {
    return this.post("/investment/save", d, null);
  }

  public uploadFile(endpoint: string, d: FormData): Promise<AxiosResponse> {
    return this.postForm(endpoint, d, null);
  }

  public deleteAccreditationDoc(
    d: Payloads.DeleteAccreditationDoc
  ): Promise<AxiosResponse> {
    return this.post("/document/remove-accreditation-document", d, null);
  }

  // public validateBankRouting(
  //   d: Payloads.PostBankRouting
  // ): Promise<AxiosResponse> {
  //   return this.post("/account/validate-routing", d, null);
  // }

  public saveACHAccount(d: Payloads.PostACHAccount): Promise<AxiosResponse> {
    return this.post("/account/save", d, null);
  }

  public saveAccreditation(
    d: Payloads.PostSaveAccreditation
  ): Promise<AxiosResponse> {
    return this.post("/investor/save-accreditation", d, null);
  }

  public saveInvestmentOfferingStatus(
    d: Payloads.PostInvestmentOfferingStatus
  ): Promise<AxiosResponse> {
    return this.post("/investment/offering-status/save", d, null);
  }

  public saveComplianceReport(
    d: Payloads.PostComplianceReport
  ): Promise<AxiosResponse> {
    return this.post("/investor/save-compliance-report", d, null);
  }

  public completeInvestment(
    d: Payloads.PostCompleteInvestment
  ): Promise<AxiosResponse> {
    return this.post("/investment/investment-complete", d, null);
  }

  public getFile(d: Payloads.PostFileDownload): Promise<AxiosResponse> {
    return this.postBlob("/document/download", d, null);
  }

  public getAllFiles(d: Payloads.PostAllFilesDownload): Promise<AxiosResponse> {
    return this.postBlob("/document/download-all", d, null);
  }

  public postConfirmUserLink(
    d: Payloads.PostConfirmUserLink
  ): Promise<AxiosResponse> {
    return this.post("/contact/email/confirm-user-link", d, null);
  }
}
