import { Request } from "@/api/services/request";
import { AxiosResponse } from "axios";
import { Dictionary } from "@/interfaces";

export interface InvestmentDocumentsResp {
  client_id: string;
  doc_url: string;
  expires_at: bigint;
}

export class v1HelloSign extends Request {
  constructor() {
    super();
  }

  public requestInvestmentDocuments(
    id: string,
    params: Dictionary
  ): Promise<AxiosResponse> {
    return this.get(`/hellosign/v1/investment-docs/${id}`, params);
  }

  public requestDocumentSignature(
    id: string,
    sid: string
  ): Promise<AxiosResponse> {
    return this.get(`/hellosign/v1/signature-docs/${id}/${sid}`, null);
  }
}
