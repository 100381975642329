<template>
  <div>
    <router-view name="default" lazy></router-view>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "InvestNowProfile",
});
</script>

<style scoped></style>
