<template>
  <router-view />
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "V1View",
});
</script>

<style scoped></style>
