import { RouteRecordRaw } from "vue-router";
import { investNowProfileV1Routes } from "@/router/routes/v1/invest-now-profile-v1";
import { investNowMakeInvestmentV1Routes } from "@/router/routes/v1/invest-now-make-investment-v1";
import V1 from "@/views/v1/V1.vue";

export const V1Routes: Array<RouteRecordRaw> = [
  {
    path: "/v1",
    component: V1,
    meta: {},
    children: [
      {
        path: "",
        redirect: { name: "home" },
      },
      // {
      //   path: "testing",
      //   component: () => import("@/views/sandbox/Sandbox.vue"),
      // },
      ...investNowProfileV1Routes,
      ...investNowMakeInvestmentV1Routes,
    ],
  },
];
