import { MutationTree } from "vuex";
import { State } from "@/store/state";
import { User, Auth } from "@/interfaces";

export enum MutationType {
  SetAuth = "SET_AUTH",
  SetUser = "SET_USER",
  SetV1SessionId = "SET_V1_SESSION_ID", // v1
}

export type Mutations = {
  [MutationType.SetAuth](state: State, auth: Auth): void;
  [MutationType.SetUser](state: State, user: User): void;
  [MutationType.SetV1SessionId](state: State, id: string): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationType.SetAuth](state: State, auth: Auth): void {
    state.auth = auth;
  },
  [MutationType.SetUser](state: State, user: User): void {
    state.user = user;
  },
  [MutationType.SetV1SessionId](state: State, id: string): void {
    state.v1SessionId = id;
  },
};
