import { AxiosResponse } from "axios";
import { Request } from "./request";

export interface PostAuth {
  email: string;
  password: string;
}

export class User extends Request {
  constructor() {
    super();
  }

  public authenticate(auth: PostAuth): Promise<AxiosResponse> {
    return this.post("/auth", auth, null);
  }
}
