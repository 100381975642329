import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import mitt, { Emitter } from "mitt";
import { store } from "./store";

// PrimeVue setup and components
import PrimeVue from "primevue/config";
import Avatar from "primevue/avatar";
import Button from "primevue/button";
import Card from "primevue/card";
import Checkbox from "primevue/checkbox";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import Menu from "primevue/menu";
import Message from "primevue/message";
import ProgressSpinner from "primevue/progressspinner";
import Ripple from "primevue/ripple";
import SelectButton from "primevue/selectbutton";
import Sidebar from "primevue/sidebar";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Toolbar from "primevue/toolbar";

import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons"
import "primeflex/primeflex.css"; //grid, flexbox, etc.

import "@/assets/sigma/layout.scss";
import "@/assets/sigma/flags/flags.css";

import "@/assets/fontawesome/css/all.css";

// setup and set the api
import { Api } from "@/api";
const api = new Api();

const eventBus: Emitter = mitt();

const app = createApp(App);
app.provide("$api", api);
app.provide("$eventBus", eventBus);
app.use(store);
app.use(router);

// PrimeVue Injections
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.directive("ripple", Ripple);

app.component("Avatar", Avatar);
app.component("Button", Button);
app.component("Card", Card);
app.component("Checkbox", Checkbox);
app.component("Column", Column);
app.component("DataTable", DataTable);
app.component("Dialog", Dialog);
app.component("Dropdown", Dropdown);
app.component("InputNumber", InputNumber);
app.component("InputText", InputText);
app.component("Menu", Menu);
app.component("Message", Message);
app.component("ProgressSpinner", ProgressSpinner);
app.component("SelectButton", SelectButton);
app.component("Sidebar", Sidebar);
app.component("Toast", Toast);
app.component("PrimeToolbar", Toolbar);

app.mount("#app");
